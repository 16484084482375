import { Col, Input, Row, Switch, Table, message } from "antd";
import { DiscountTable } from "pages/Applications/Applications";
import React, { useCallback, useEffect, useState } from "react";
import { Heading } from "ui/Heading";
import { Link } from "react-router-dom";
import { Container } from "ui/Layout/Container";
import { MdCancel } from "react-icons/md";
import useDebounce from "pages/utils/hooks/useDebounce";
import { httpAPIInviteSND } from "api";
import { Capitalize, formatPhoneNumber } from "pages/utils/fn";
import { UserAvatar } from "ui/Avatar";
import ConfirmDeactivateMoal from "./ConfirmDeactivateModal";
import { useSelector } from "react-redux";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [switchLoading, setSwitchLoadings] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [modalBtnLoading, setModalBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const [indexVal, setIndexVal] = useState("");
  const Search = Input.Search;
  const { user } = useSelector((state) => state.user);

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
  };

  const emitEmpty = () => {
    setSearchBarText("");
  };

  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 500);

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/all-users?pageSize=${10}&pageNumber=${1}&query=${encodeURIComponent(
          deBounceSearchBarText
        )}`,
        "",
        "GET"
      ).then((res) => {
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        let value = res?.data?.Data;
        const launchOptimistic = value?.map((elem, idx) => ({
          ...elem,
          key: idx,
        }));
        setSearchData(launchOptimistic);
        setLoading(false);
      });
    } else {
      let newPagination = { ...pagination };
      getAllUserList(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  const getAllUserList = useCallback(
    (pagination, search = "") => {
      setLoading(true);
      httpAPIInviteSND(
        `/all-users?pageSize=${pagination.pageSize}&pageNumber=${
          pagination.pageNumber
        }&query=${encodeURIComponent(searchBarText ? searchBarText : search)}`,
        "",
        "GET"
      )
        .then((payload) => {
          if (payload?.data) {
            setPagination((p) => ({ ...p, total: payload?.data?.total }));
            let value = payload?.data?.Data;
            const launchOptimistic = value?.map((elem, idx) => ({
              ...elem,
              key: idx,
            }));
            setSearchData(launchOptimistic);
            setLoading(false);
          } else {
            setSearchData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error) {
            message.error(error.message);
          }
        });
    },

    [pagination]
  );

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    getAllUserList(newPagination, searchBarText);
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  const onChangeHandler = (record) => {
    if (record?.is_deactivated === 0) {
      const objIndex = searchData?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = searchData[objIndex];
      objValue.is_deactivated = 1;
      searchData[objIndex] = objValue;
      setSearchData((val) => [...val]);
      setSwitchLoadings(true);
      setModalBtnLoading(true);
      httpAPIInviteSND(
        `/deactivate-user/${record?.id}/${user?.id}?is_deactivated=${1}`,
        "",
        "GET"
      )
        .then((payload) => {
          setSwitchLoadings(false);
          setModalBtnLoading(false);
          if (payload?.data) {
            let newPagination = {
              ...pagination,
            };
            newPagination.pageNumber = newPagination.current;
            // getAllUserList(newPagination, "amit");
            setPagination((p) => ({ ...p, ...newPagination }));
          }
          setModalState(false);
        })
        .catch((error) => {
          setModalBtnLoading(false);
          setSwitchLoadings(false);
          if (error) {
            message.error(error.message);
          }
        });
    } else {
      const objIndex = searchData?.findIndex((obj) => {
        return obj.id === record.id;
      });
      let objValue = searchData[objIndex];
      objValue.is_deactivated = 0;
      searchData[objIndex] = objValue;
      setSearchData((val) => [...val]);
      httpAPIInviteSND(
        `/activate-user/${record?.id}/${user?.id}?is_deactivated=${0}`,
        "",
        "GET"
      )
        .then((payload) => {
          setSwitchLoadings(false);
          setModalBtnLoading(false);
          if (payload?.data) {
            let newPagination = {
              ...pagination,
            };
            newPagination.pageNumber = newPagination.current;
            // getAllUserList(newPagination, "amit");
            setPagination((p) => ({ ...p, ...newPagination }));
          }
        })
        .catch((error) => {
          setModalBtnLoading(false);
          setSwitchLoadings(false);
          if (error) {
            message.error(error.message);
          }
        });
    }
  };

  const imagePath = (record) => {
    let url;
    if (record?.image !== null && record?.image !== "") {
      let parseImage = JSON.parse(record?.image);
      url = parseImage?.path;
    }
    return url;
  };

  const columns = [
    {
      title: "Avatar",
      dataIndex: "",
      width: "12%",
      render: (_, record) => {
        return (
          <>
            <UserAvatar
              fullName={`${record?.firstName || ""} ${record?.lastName || ""}`}
              className="user__icon"
              src={imagePath(record)}
            />
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      width: "16%",
      render: (_, record) => {
        return (
          <div>
            <td>
              {Capitalize(record.firstName)} {Capitalize(record.lastName)}
            </td>
          </div>
        );
      },
    },
    {
      title: "Phone No",
      dataIndex: "phoneNumber",
      width: "18%",
      render: (_, record) => {
        return (
          <div>
            <span>{formatPhoneNumber(record.phoneNumber)}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "is_deactivated",
      width: "15%",
      render: (_, record, index) => {
        return (
          <>
            <Switch
              loading={indexVal === index && switchLoading}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              defaultChecked={record?.is_deactivated === 0 ? true : false}
              checked={record?.is_deactivated === 0 ? true : false}
              onChange={() => {
                setIndexVal(index);
                if (record?.is_deactivated === 0) {
                  setModalState(true);
                  setSelectedUser(record);
                } else {
                  onChangeHandler(record);
                }
              }}
            />
            <ConfirmDeactivateMoal
              onChangeHandler={onChangeHandler}
              modalBtnLoading={modalBtnLoading}
              userName={selectedUser}
              modalState={modalState}
              onCancel={() => setModalState(false)}
            />
          </>
        );
      },
    },
  ];

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row gutter={[20, 20]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Heading>
            <Link to="/users">Users</Link>
          </Heading>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </Col>
      </Row>
      <DiscountTable>
        <Table
          className="app__table"
          columns={columns}
          dataSource={[...searchData]}
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
          //   onRow={(item) => ({
          //     onClick: () => {
          //     },
          //   })}
          scroll={{ x: 1300, y: 550 }}
        />
      </DiscountTable>
    </Container>
  );
};

export default Users;
