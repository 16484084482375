import { Col, Input, Row, Switch, Table } from "antd";
import { httpAPIInviteSND } from "api";
import { DiscountTable } from "pages/Applications/Applications";
import { formatPhoneNumber } from "pages/utils/fn";
import useDebounce from "pages/utils/hooks/useDebounce";
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";

const Properties = () => {
  const [loading, setLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [orgName, setOrgName] = useState("");
  const [propertyArr, setPropertyArr] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });
  const Search = Input.Search;
  const org_id = localStorage.getItem("org_id");
  const deBounceSearchBarText = useDebounce(searchBarText.trim(), 1000);

  useEffect(() => {
    if (deBounceSearchBarText?.length >= 3) {
      setLoading(true);
      httpAPIInviteSND(
        `/properties-by-org?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&id=${org_id}`,
        "",
        "GET"
      ).then((res) => {
        setOrgName(res?.data?.org_name);
        setPagination((p) => ({ ...p, total: res?.data?.total }));
        let value = res?.data?.Data;
        const launchOptimistic = value?.map((elem, idx) => ({
          ...elem,
          key: idx,
        }));
        setPropertyArr(launchOptimistic);
        setLoading(false);
      });
    } else {
      let newPagination = { ...pagination };
      getProperty(newPagination, searchBarText);
    }
  }, [deBounceSearchBarText]);

  const getProperty = useCallback(() => {
    setLoading(true);
    httpAPIInviteSND(
      `/properties-by-org?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&id=${org_id}`,
      "",
      "GET"
    ).then((res) => {
      setOrgName(res?.data?.org_name);
      setPagination((p) => ({ ...p, total: res?.data?.total }));
      let value = res?.data?.Data;
      const launchOptimistic = value?.map((elem, idx) => ({
        ...elem,
        key: idx,
      }));
      setPropertyArr(launchOptimistic);
      setLoading(false);
    });
  });

  const columns = [
    {
      title: "Property Name",
      dataIndex: "unit_name",
      key: "unit_name",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip Code",
      dataIndex: "zipcode",
      key: "zipcode",
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (_, record) => {
        return (
          <div>
            <span>{formatPhoneNumber(record.phonenumber)}</span>
          </div>
        );
      },
    },
    {
      title: "#No of Units",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (_, record) => {
        return (
          <>
            <Switch
              //   loading={}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              //   defaultChecked={}
              //   checked={}
              onChange={() => {
                // if (record?.is_deactivated === 0) {
                //   setModalState(true);
                //   setSelectedUser(record);
                // } else {
                //   onChangeHandler(record);
                // }
              }}
            />
          </>
        );
      },
    },
  ];

  const emitEmpty = () => {
    setSearchBarText("");
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    setLoading(true);
    newPagination.pageNumber = newPagination.current;
    setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Row gutter={[10, 10]}>
            <Col>
              <Heading>
                <Link to="/organizations">Properties</Link>
              </Heading>
            </Col>
            <Col>
              <h3> ({orgName})</h3>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={8}
          className="search__filter"
        >
          <Search
            ref={(ele) => searchBarText === ele}
            suffix={suffix}
            placeholder="Search..."
            value={searchBarText}
            // onChange={onSearch}
            // onPressEnter={onSearch}
          />
        </Col>
      </Row>

      <DiscountTable>
        <Table
          columns={columns}
          dataSource={[...propertyArr]}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 1300, y: 550 }}
          onRow={(item) => ({
            onClick: (event) => {
              event.isDefaultPrevented();
            },
          })}
        />
      </DiscountTable>
    </Container>
  );
};

export default Properties;
